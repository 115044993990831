body {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	color: white;
	background-color: var(--blue);

	div {
		display: block;
		max-width: 700px;

		> h1 {
			margin-bottom: 30px;
			font-size: 50px;
			text-align: left;
		}

		> p {
			font-size: 22px;
			line-height: 30px;
		}

		> a {
			display: block;
			width: 300px;
			padding: 10px 15px;
			margin-top: 40px;
			text-align: center;
			font-weight: bold;
			font-size: 22px;
			text-decoration: none;
			color: white;
			border: 4px solid white;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
